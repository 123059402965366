import React , { useEffect } from "react"
import SEO from "../../components/SEO/SEO"

import Layout from "../../components/layout"
import Container from "../../components/container/container"
import Gumshoe from "gumshoejs"

const TheTechnicalReport069 = () => {

  useEffect(() => {
    var myyElement = document.getElementById("js-sticky");

    window.addEventListener('scroll', function (evt) {
      var Positionsss =  GetTopLeft ();
      if (Positionsss.toppp  > 95)	{ myyElement.style.position="relative"; myyElement.style.top = "0px"; }
      else							{ myyElement.style.position="fixed";	myyElement.style.top = "90px";}
    });

    function GetOffset (object, offset) {
      if (!object) return;
      offset.x += object.offsetLeft;       offset.y += object.offsetTop;
      GetOffset (object.offsetParent, offset);
    }
    function GetScrolled (object, scrolled) {
      if (!object) return;
      scrolled.x += object.scrollLeft;    scrolled.y += object.scrollTop;
      if (object.tagName.toLowerCase () !== "html") {          GetScrolled (object.parentNode, scrolled);        }
    }
    function GetTopLeft () {
      var offset = {x : 0, y : 0};		GetOffset (myyElement.parentNode, offset);
      var scrolled = {x : 0, y : 0};		GetScrolled (myyElement.parentNode.parentNode, scrolled);
      var posX = offset.x - scrolled.x;	var posY = offset.y - scrolled.y;
      return {lefttt: posX , toppp: posY };
    }

    var gumshoeElement = document.getElementById("js-article-menu");

    if(gumshoeElement !== null) {
      var spy = new Gumshoe('#js-article-menu a', {
      offset: 40, // how far from the top of the page to activate a content are
      });
    }
  })

  return (
  <Layout>
    <SEO
      title="The Technical Report 069"
      desc="Main purpose, Communication, Auto Configuration Server, Session"
      canonical="https://tr069.cloud/articles/the-technical-report-069/"
      pathname="/articles/the-technical-report-069"
    />
    <article className="article">
      <div className="article__bg--usp">
        <div className="article-intro">
          <Container>
            <h1 className="article-intro__title">The Technical Report 069</h1>
          </Container>
        </div>
      </div>
      <Container>
        <div>
        <nav className="article-menu" id="js-article-menu">
          <ol id="js-sticky">
            <li>
              <a href="#intro">Intro</a>
            </li>
            <li>
              <a href="#main-purpose">Main purpose</a>
            </li>
            <li>
              <a href="#communication">Communication</a>
            </li>
            <li>
              <a href="#auto-configuration-server">Auto Configuration Server</a>
            </li>
            <li>
              <a href="#session">Session</a>
            </li>
          </ol>
        </nav>
        <div className="article-content">
          <span className="article-content__first-line-bug"></span>
          <div className="fixed-anchor" id="intro">
            <p>
              The Technical Report 069 (TR-069) is a technical specification, that was developed by the non-profit organization Broadband Forum in the first time in 2004. It describes and defines an application layer protocol for remote management of customer-premises equipment (CPE) connected to an Internet Protocol (IP) network.
            </p>
          </div>
          <div className="fixed-anchor" id="main-purpose">
            <h2>Main purpose</h2>
            <p>
              The CPE WAN Management Protocol (CWMP) defines support functions for auto-configuration, software or firmware image management, software module management, status and performance managements, and diagnostics. This application layer protocol uses SOAP (Simple Object Access Protocol) information as a transmission tool. All data is transmitted in XML format. The main purpose of creating a CWMP is to unify the means and methods of managing CPE in the networks of providers, regardless of the manufacturers and models of CPE. Achieving these goals would ensure the quality of mass services, optimize the costs of their support, simplify the procedure for access to the service by subscribers.
            </p>
          </div>
          <div className="fixed-anchor" id="communication">
            <h2>Communication</h2>
            <p>
              As a bidirectional SOAP/HTTP-based protocol, CWMP provides the communication between a CPE and auto configuration server (ACS). It includes both a safe auto configuration and the control of other CPE management functions within an integrated framework. The protocol is addressed for growing number of different Internet access devices such as modems, routers, gateways, as well as end-user devices which connect to the Internet, such as set-top boxes, and VoIP-phones.
            </p>
          </div>
          <div className="fixed-anchor" id="auto-configuration-server">
            <h2>Auto Configuration Server</h2>
            <p>
              CWMP defines the principles of interaction between subscriber devices (CPE) and the Auto-Configuration Server (ACS). The TR-069 standard was developed for automatic configuration and management of these devices by Auto Configuration Servers (ACS). ACS (Auto Configuration Server) is an application server that automates the execution of basic methods of control over subscriber devices (CPE), it interacts with subscriber equipment, handles requests from devices and has the ability to add additional services. With TR-069 in the client device this device can connect to an ACS server that installed and operating in the network of Internet service provider (ISP), after that the ACS server allows you to manage software version of subscriber devices and their updates, to make autoconfiguration of already running devices or to change settings VoIP, IPTV, and also to analyze log files, performance of device or to carry out diagnostics.
            </p>
          </div>
          <div className="fixed-anchor" id="session">
            <h2>Session</h2>
            <p>
              A session can be initiated either by the CPE or ACS server. For successful interaction with ACS server, the subscriber device must have an IP address and a TR-069 client as a part of software. To ensure secure connection, TR-069 uses SSL and TLS protocols, which is especially important if the server is located in the external network.
            </p>
          </div>
        </div>
      </div>
      </Container>
    </article>
  </Layout>
  )
}

export default TheTechnicalReport069
