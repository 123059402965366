/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header/header"
import Footer from "./footer/footer"
import PolicyAgree from "./policy-agree/policy-agree"

import "./layout.module.scss"
import "./global.module.scss"

const Layout = ({ children, mainClassName = "" }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main className={`main ${mainClassName}`}>{children}</main>
      <Footer/>
      <PolicyAgree/>
    </>
  )
}

export default Layout
