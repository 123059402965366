import React from "react"
import { Link } from "gatsby"
import Container from "../container/container"

import "./header.module.scss"
import "../container/container"

import logo from "../../images/logo.svg"

const Header = () => (
  <div className="header-fixed">
    <aside className="header-promo">
      {/*<Container className="header-promo__text">*/}
      {/*    <span>Do you want to test devices with different protocols?</span> <a className="header-promo__link" href={`${process.env.ANGULAR_APP_URL}`} target="_blank" rel="noopener noreferrer" title="Interoperability testing portal">*/}
      {/*    Interoperability testing portal*/}
      {/*  </a>*/}
      {/*</Container>*/}
    </aside>
    <header className="header">
      <Container className="header__container">
        <Link to="/#" title="Main page">
          <img className="header__image" src={`${logo}`} width="40" height="40" alt="Interoperability testing portal" title="Interoperability testing portal">
          </img>
        </Link>
        <nav>
          <Link className="header__link" to="/#articles">
            Articles
          </Link>
          <Link className="header__link" to="/sitemap">
            Sitemap
          </Link>
          <Link className="header__link" to="/privacy-policy">
            Privacy Policy
          </Link>
        </nav>
      </Container>
    </header>
  </div>
)

export default Header
