import React, {useState, useEffect} from "react"

import Cookies from 'universal-cookie';

import "./policy-agree.module.scss"

const PolicyAgree = () => {

    const cookies = new Cookies();

    const [policyClassName, setPolicyClassName] = useState('visually-hidden')

    useEffect(() => {
        if(cookies.get('cookie_notice_accepted') !== 'true') {
            setPolicyClassName('policy-agree')
        }
    })

    const clickBtn = () => {
        let date = new Date();
        date.setYear(date.getFullYear()+1);
        cookies.set('cookie_notice_accepted', 'true', { path: '/' , expires: date });
        document.getElementById("policy-agree").className = "visually-hidden";     
    }

    return (
      <section className={policyClassName} id="policy-agree">
        <div className="container">
          <h2 className="visually-hidden">Privacy policy confirmation</h2>
          <p>We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.<button className="policy-agree__btn" id="policy_agree" type="button" onClick={() => clickBtn()}>Ok</button></p>
        </div>
      </section>
    )
}

export default PolicyAgree