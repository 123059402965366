import React from "react"
import Container from "../container/container"

import "./footer.module.scss"

const year = new Date().getFullYear()

const Footer = () => (
  <footer>
    <Container>
      <p>{"© " + year + " Interop Cloud"}</p>
    </Container>
  </footer>
)

export default Footer
